html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.body-container {
  margin-top: 0 !important;
  flex-grow: 1;
}

.MuiLinearProgress-root {
  width: 100% !important;
  background-color: #efefef !important;
}

.MuiLinearProgress-bar {
  background-color: #0fabbb !important;
}

.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #cccccc;
  padding-top: 0px !important;
  height: 90vh;
}

.background-image-container {
  background-image: url("../assets/BGimage.jpg");
  background-position: top;
  background-repeat: no-repeat;
}

.question-form {
  gap: 28px !important;
  margin: 68px 80px !important;
  width: 100% !important;
}

.question-text {
  font-family: "Inter" !important;
  font-size: 24px !important;
  font-weight: 600 !important;
  line-height: 30px !important;
  letter-spacing: 0em !important;
  text-align: left;
  color: #12313b;
}

.verify-otp-text {
  font-family: "Inter" !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  line-height: 30px !important;
  letter-spacing: 0em !important;
  text-align: left;
  color: #12313b;
}

.verify-otp-helper-text {
  font-family: "Inter" !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  letter-spacing: 0em !important;
  text-align: left;
  color: #667085;
}

.otp-input-text {
  font-family: "Inter" !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 20px !important;
  letter-spacing: 0em !important;
  text-align: left;
}

.confirmation-text {
  display: flex;
  align-items: center;
  margin-top: 28px !important;
  font-family: "Inter" !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  letter-spacing: 0em !important;
  text-align: left;
}

.user-form {
  gap: 28px !important;
  margin: 68px 80px !important;
  width: 100% !important;
  padding: 40px !important;
  background-color: rgba(255, 255, 255, 0.9);
}

.user-detail-text {
  font-family: "Inter" !important;
  font-size: 36px !important;
  font-weight: 600 !important;
  line-height: 44px !important;
  letter-spacing: 0px !important;
  text-align: left;
}

.question-helper-text {
  font-family: "Inter" !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 19px !important;
  letter-spacing: 0em !important;
  text-align: left;
  color: #667085;
}

.continue-btn {
  background-color: #0fabbb !important;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  text-transform: capitalize !important;
}

.get-otp-btn {
  width: 50% !important;
  height: 56px;
  border-radius: 8px;
}

.continue-btn.Mui-disabled {
  background-color: #d9d9d9 !important;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: white !important;
}

.submit-btn {
  background-color: #60d669 !important;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  text-transform: capitalize !important;
}

.submit-btn.Mui-disabled {
  background-color: #d9d9d9 !important;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: white !important;
}

.header-text {
  font-family: "Inter";
  font-weight: 600;
  font-size: 22px;
  line-height: 24px;
}

.form-action-panel {
  display: flex;
  justify-content: space-between;
}

.summary-container {
  margin: 24px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex-grow: 1;
}

.summary-title {
  display: flex;
  align-items: center;
  gap: 12px;
}

.summary-section-title {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  padding: 8px;
  box-shadow: none !important;
  margin-bottom: 16px;
}

.summary-section-item-text {
  font-family: "Inter" !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  text-align: left;
}

.summary-section-item {
  margin: 8px 0px;
  display: flex;
  gap: 8px;
  align-items: center;
}

.option-card {
  color: #667085 !important;
  border-color: #667085 !important;
  width: -webkit-fill-available;
  padding: 16px !important;
  justify-content: flex-start !important;
  text-transform: unset !important;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.otp-input-control {
  width: 75% !important;
}

.option-card:hover {
  color: #0fabbb !important;
  border-color: #0fabbb !important;
  background-color: #f1f9fc;
}

.option-card:hover .option-card-text svg {
  stroke: #0fabbb !important;
  fill: #ecf9fa !important;
}

.option-card.selected {
  color: #0fabbb !important;
  border-color: #0fabbb !important;
  background-color: #f1f9fc;
}

.option-card.selected .option-card-text svg {
  stroke: #0fabbb !important;
  fill: #ecf9fa !important;
}

.option-card:hover .option-card-text svg#multimedia-control {
  fill: #0fabbb !important;
}
.option-card:hover .option-card-text svg#accident-protection {
  fill: #0fabbb !important;
}
.option-card:hover .option-card-text svg#voice-command {
  fill: #0fabbb !important;
}
.option-card:hover .option-card-text svg#home-security {
  fill: #0fabbb !important;
}
.option-card.selected .option-card-text svg#multimedia-control {
  fill: #0fabbb !important;
}

.option-card.selected .option-card-text svg#accident-protection {
  fill: #0fabbb !important;
}

.option-card.selected .option-card-text svg#voice-command {
  fill: #0fabbb !important;
}

.option-card.selected .option-card-text svg#home-security {
  fill: #0fabbb !important;
}

.option-card-text {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
}

.custom-input-text {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
}

.estimation-text {
  font-family: "Inter" !important;
  font-size: 48px !important;
  font-weight: 700 !important;
  line-height: 58px !important;
  letter-spacing: 0px !important;
  text-align: center !important;
}

.hold-on-text {
  font-family: "Inter" !important;
  font-size: 36px !important;
  font-weight: 600 !important;
  line-height: 44px !important;
  text-align: center !important;
}

.estimation-helper-text {
  font-family: "Inter" !important;
  font-size: 24px !important;
  font-weight: 500 !important;
  line-height: 36px !important;
  letter-spacing: 0em !important;
  text-align: center !important;
}

.estimation-helper-text span {
  color: #0fabbb;
}

.hold-on-helper-text {
  font-family: Inter !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  text-align: center !important;
}

.hold-on-sub-helper-text {
  font-family: Inter !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 18px !important;
  letter-spacing: 0em !important;
  text-align: center !important;
}

.resend-link {
  cursor: pointer;
  color: #0fabbb;
}

.resend-link.disabled {
  cursor: not-allowed;
}

.dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: black;
  border-radius: 50%;
  margin: 0 5px;
  animation: loading 1.5s infinite ease-in-out;
}

@keyframes loading {
  0%,
  80%,
  100% {
    opacity: 0.3;
  }
  40% {
    opacity: 1;
  }
}

#dot1 {
  animation-delay: -0.5s;
}

#dot2 {
  animation-delay: -0.25s;
}

#dot3 {
  animation-delay: 0s;
}

.estimation-sub-helper-text {
  font-family: "Inter" !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 26px !important;
  letter-spacing: 0em !important;
  text-align: center !important;
  color: #667085;
}

.icon-button-wrapper {
  display: "flex";
  justify-content: "center";
  gap: "20px";
}

.icon-button {
  text-transform: none !important;
  color: #0fabbb !important;
  border-color: #0fabbb !important;
  border-radius: 8px !important;
  padding: 16px 32px !important;
  font-size: 16px !important;
  line-height: 20px !important;
}

.get-otp-btn {
  width: 75% !important;
  height: 56px;
  border-radius: 8px;
}

.verify-otp-form {
  width: 50% !important;
}

.option-date-input {
  border-color: #667085 !important;
  color: #667085 !important;
}

.option-date-input:focus-visible {
  border-color: #0fabbb !important;
  color: #0fabbb !important;
  outline-color: #0fabbb !important;
}

.option-date-input:hover {
  color: #0fabbb !important;
  border-color: #0fabbb !important;
  background-color: #f1f9fc !important;
  outline-color: #0fabbb !important;
}

@media (max-width: 768px) {
  .option-card {
    padding: 12px !important;
    gap: 8px;
  }
  .question-text {
    font-size: 20px !important;
  }
  .question-form {
    gap: 20px !important;
    margin: 16px !important;
  }
  .user-detail-text {
    font-family: "Inter" !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    line-height: 30px !important;
  }
  .user-detail-text span {
    font-weight: 900;
    font-family: "Inter" !important;
    font-size: 40px;
  }
  .user-form {
    gap: 16px !important;
    margin: 24px !important;
    padding: 16px !important;
    border-radius: 12px;
  }
  .verify-otp-container {
    align-items: flex-start;
  }
  .otp-input {
    width: 100% !important;
  }
  .confirmation-text {
    margin-top: 24px !important;
  }
  .estimation-text {
    font-size: 36px !important;
  }
  .estimation-helper-text {
    font-size: 20px !important;
  }
  .icon-button-wrapper {
    flex-direction: column;
  }
  .otp-input-control {
    width: 100% !important;
  }
  .get-otp-btn {
    width: 100% !important;
  }
  .verify-otp-form {
    width: 100% !important;
  }
}
